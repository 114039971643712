
.containercalculator{
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    margin: 0 auto;
    width: 300px;
    align-items: center;
    text-align: center;
    border: 5px solid grey;
    background-color: rgb(0, 0, 0);
    border-radius: 8px;
}

.keypad{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: minmax(60px, auto);
}
.inputCalculator {
    height: 70px;
    width: 292px;
    background-color: white;
    color: black;
    text-align: right;
    font-size: 25px;
    font-weight: 500;
    letter-spacing: 1px;
    border: 1px solid black;
}

.inputCalculator::placeholder {
    color: #000000;
}

#clearcalculator{
    grid-column: 1/3 !important;
    grid-row: 1;
    color: rgb(0, 0, 0);
}

#resultcalculator{
    grid-column: 3/5;
    grid-row: 5;
    color: rgb(0, 0, 0);
}
#backspacecolor{
    color: black;
}
/* .button{
    margin: 5px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: rgba(48, 47, 47, 0.986);
    color: white;
    font-weight: 500;
} */

input:focus, input:active, button:focus, button:active{
    outline: none;
}
.highlight{
    background-color: #f9f9f9;
}